import React from "react"
import Head from "next/head"
import styles from "./Default.module.scss"
import cx from "clsx"

import Footer from "../../components/Footer/Footer"
import Container from "../Container/Container"

import ClientOnly from "../../utils/ClientOnly"

import BannerEmail from "../../components/BannerEmail/BannerEmail"
import FabContact from "../../components/FabContact/FabContact"
import NavbarClean from "../../components/NavbarClean/NavbarClean"
import NavbarWeb from "../../components/NavbarWeb/NavbarWeb"
import Navbar from "../../components/Navbar/Navbar"
import MobileMenu from "../../components/MobileMenu/MobileMenu"

const Default = ({
  title,
  cta,
  clean,
  web,
  full,
  narrow = false,
  className,
  gray,
  children,
  navbar = "default",
  layout = "default",
  noEmailReminder = false,
  withFooter,
  withFab,
  withMobileMenu,
  ...restProps
}) => {
  return (
    <>
      <Head>
        <title>{title || "Talent Indonesia"}</title>
      </Head>
      {navbar === "clean" && <NavbarClean cta={cta} />}
      {navbar === "web" && <NavbarWeb />}
      {navbar === "default" && <Navbar />}

      {!noEmailReminder && (
        <ClientOnly>
          <BannerEmail />
        </ClientOnly>
      )}

      {(layout === "full" || layout === "web") && (
        <Container
          bleed
          as="main"
          className={cx(styles.fullContent, { [styles.gray]: gray })}
        >
          {children}
        </Container>
      )}
      {layout === "default" && (
        <Container
          bleed
          as="main"
          className={cx(styles.content, { [styles.gray]: gray })}
        >
          <Container narrow={narrow} className={styles.main}>
            {children}
          </Container>
        </Container>
      )}
      {layout === "narrow" && (
        <Container
          bleed
          as="main"
          className={cx(styles.content, { [styles.gray]: gray })}
        >
          <Container narrow className={styles.main}>
            {children}
          </Container>
        </Container>
      )}
      {withFooter && <Footer />}
      {withFab && <FabContact />}
      {withMobileMenu && <MobileMenu />}
    </>
  )
}

export default Default
