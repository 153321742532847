import styles from "./Navbar.module.scss"
import React from "react"
import cx from "clsx"

import { BrandNavbar as BrandLogo } from "../Brand/Brand"

import Container from "../../layouts/Container/Container"

import NavbarMenu from "../NavbarMenu/NavbarMenu"
import Link from "../Link/Link"
import NavbarPrimary from "../NavbarPrimary/NavbarPrimary"
import AvatarArea from "../AvatarArea/AvatarArea"
import { Box, Inline } from "tenka"

const Navbar = ({ bleed, light, children, className, ...restProps }) => {
  return (
    <Container
      className={cx({ [styles.wrapper]: !light, [styles.light]: light })}
      bleed
    >
      <Container
        as="nav"
        bleed
        className={cx({
          [styles.root]: true,
          [className]: className,
        })}
        {...restProps}
      >
        <Inline
          className={styles.area}
          display="flex"
          justify="between"
          align="center"
        >
          <Inline align="center">
            <nav className={styles.brandWrapper}>
              <Link to="/" className={styles.brand}>
                <BrandLogo
                  style={{ height: 48, marginTop: -8, marginBottom: -8 }}
                />
              </Link>
            </nav>
            <NavbarPrimary>
              <NavbarMenu to="/dashboard/home">Dashboard</NavbarMenu>
              <NavbarMenu to="/lowongan">Lowongan</NavbarMenu>
              <NavbarMenu to="/dashboard/lamaran">Lamaran</NavbarMenu>
            </NavbarPrimary>
          </Inline>
          <div>
            <AvatarArea />
          </div>
        </Inline>
      </Container>
    </Container>
  )
}

export default Navbar
