import styles from "./NavbarClean.module.scss"
import React from "react"
import cx from "clsx"

import { BrandNavbar as BrandLogo } from "../Brand/Brand"

import Container from "../../layouts/Container/Container"
import Link from "../Link/Link"
import NavbarSecondary from "../NavbarSecondary/NavbarSecondary"
import NavbarPrimary from "../NavbarPrimary/NavbarPrimary"
import AvatarArea from "../AvatarArea/AvatarArea"

const NavbarClean = ({
  bleed,
  cta,
  light,
  children,
  className,
  ...restProps
}) => {
  return (
    <Container
      className={cx({ [styles.wrapper]: !light, [styles.light]: light })}
      bleed
    >
      <Container
        component="nav"
        bleed
        className={cx({
          [styles.root]: true,
          [className]: className,
        })}
        {...restProps}
      >
        <NavbarPrimary>
          <Link to="/" style={{ marginRight: 24 }}>
            <BrandLogo style={{ height: 40 }} />
          </Link>
        </NavbarPrimary>
        <NavbarSecondary>
          {cta && cta}
          <AvatarArea />
        </NavbarSecondary>
      </Container>
    </Container>
  )
}

export default NavbarClean
